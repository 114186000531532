import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Period } from 'common/types'; // Adjust as per your actual types
import { TranslationPrefixes } from 'consts/translationPrefixes';
import { addDays, addMonths, endOfDay, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    onDatesChange: (startDate: Date, endDate: Date) => void;
    periods?: Array<Period>;
}

const PeriodSelection: React.FC<Props> = ({ onDatesChange, periods = [
    'Today',
    'Last 7 days',
    'Last 15 days',
    'Last 30 days',
    'Last 3 months',
    'Last 6 months',
    'This year',
    'Custom',
], children }) => {
    const { t } = useTranslation();
    const { sideBar } = TranslationPrefixes;
    const module = 'modules.periods.';

    const [selectedPeriod, setSelectedPeriod] = useState<Period>('Today');
    const [customStartDate, setCustomStartDate] = useState<string>('');
    const [customEndDate, setCustomEndDate] = useState<string>('');

    const handlePeriodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value as Period;
        setSelectedPeriod(value);

        if (value !== 'Custom') {
            const { startDate, endDate } = calculateDates(value);
            onDatesChange(startDate, endDate);
        }
    };

    const handleCustomDateChange = (type: 'start' | 'end', value: string) => {
        if (type === 'start') {
            setCustomStartDate(value);
        } else {
            setCustomEndDate(value);
        }
    };

    const handleApplyCustomPeriod = () => {
        const customStart = new Date(customStartDate);
        const customEnd = new Date(customEndDate);

        if (!isNaN(customStart.getTime()) && !isNaN(customEnd.getTime()) && customStart <= customEnd) {
            onDatesChange(startOfDay(customStart), endOfDay(customEnd));
        } else {
            console.error('Invalid custom date range');
        }
    };

    const calculateDates = (period: Period) => {
        const today = new Date();
        let startDate = startOfDay(today);
        let endDate = endOfDay(today);

        switch (period) {
            case 'Last 7 days':
                startDate = startOfDay(addDays(today, -6));
                break;
            case 'Last 15 days':
                startDate = startOfDay(addDays(today, -14));
                break;
            case 'Last 30 days':
                startDate = startOfDay(addDays(today, -29));
                break;
            case 'Last 3 months':
                startDate = startOfMonth(addMonths(today, -2));
                break;
            case 'Last 6 months':
                startDate = startOfMonth(addMonths(today, -5));
                break;
            case 'This year':
                startDate = startOfYear(today);
                break;
            default:
                // 'Today' and 'Custom' handled by default values
                break;
        }

        return { startDate, endDate };
    };

    return (
        <Grid container style={{ flexGrow: 1, marginBottom: 3 }} spacing={2} alignItems="flex-end">
            <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                    <InputLabel id="select-period">{t(module + 'period')}</InputLabel>
                    <Select
                        labelId="select-period"
                        value={selectedPeriod}
                        onChange={handlePeriodChange}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 36 * 4 + 12,
                                },
                            },
                        }}
                    >
                        {periods.map((period) => (
                            <MenuItem key={period} value={period}>
                                {t(module + period)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {selectedPeriod === 'Custom' && (
                <>
                    <Grid item xs={12} md={3}>
                        <TextField
                            id="custom-start-date"
                            label={t('modules.periods.customStartDate')}
                            type="date"
                            value={customStartDate}
                            onChange={(e) => handleCustomDateChange('start', e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            id="custom-end-date"
                            label={t('modules.periods.customEndDate')}
                            type="date"
                            value={customEndDate}
                            onChange={(e) => handleCustomDateChange('end', e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box mt={2}>
                            <Button onClick={handleApplyCustomPeriod} variant="outlined" fullWidth>
                                {t('modules.periods.applyCustomPeriod')}
                            </Button>
                        </Box>
                    </Grid>
                </>
            )}
            {children}
        </Grid>
    );
};

export default PeriodSelection;
