import { Box, Grid, Typography, createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { ComponentProps } from "react";
import twoImg from "../../assets/2.png";
import logoFooter from "../../assets/footer-logo.png";
import logo from "../../assets/logo.png";

const styles = () => {
	return createStyles({
		"@global": {
			"#root": {
				padding: "1px",
			},
			body: {
				backgroundSize: "cover",
				backgroundPosition: "bottom center",
			},
			"body, html": {
				padding: 0,
				margin: 0,
				height: "100%",
				width: "100%",
			},
		},
		container: {
			width: "1200px",
			margin: "100px auto 40px auto",
			textAlign: "center",
			maxWidth: "calc(100% - 40px)",
			paddingTop: "1px",
			paddingBottom: "1px"
		},
		logo: {
			width: "200px",
			display: "block",
			margin: "0 auto 25px auto",
		},
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function SignIn(props: ComponentProps<"body"> & WithStyles): JSX.Element {
	const { classes, children } = props;
	return (
		<Box className={classes.container}>
			<img src={logo} alt="logo" className={classes.logo} />
			<Box marginBottom={4}><Typography variant="h5" color="primary">MAXX IQ</Typography></Box>
			<Box sx={{ width: "400px", maxWidth: "100%", marginLeft: "auto", marginRight: "auto", mb: 7 }}>
				{children}
			</Box>
			<Box marginBottom={6}>
				<Grid container spacing={2} alignItems="center" justifyContent="center" >
					<img src={logoFooter} height="50px" width="auto" style={{ marginRight: "30px" }}></img>
					<Box sx={{ flex: 1, }}>
						<Typography align="left" variant="body1">
							Софтуерът е закупен по проект BG- RRP-3.004-2034-C01, чрез процедура BG-RRP-3.004
							Технологична модернизация, финансиран по Национален план за възстановяване и
							устойчивост.
							<br />
							<br />
							Модел: Специализиран софтуер за управление на онлайн магазини в платформата
							Амазон
							Сериен номер: BB2404MQ1
						</Typography>
					</Box>
					<img src={twoImg} height="100px" width="auto" style={{ marginLeft: "30px" }}></img>
				</Grid>
			</Box>
		</Box>
	);
}

export default withStyles(styles)(SignIn);
