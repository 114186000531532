import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Languages } from "./interfaces/language";
import bgTranslations from "./translations/bg.json";
import enTranslations from "./translations/en.json";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: Languages.BG,
    ns: ["translations"],
    supportedLngs: [Languages.BG, Languages.EN],
    resources: {
      [Languages.EN]: {
        translations: enTranslations,
      },
      [Languages.BG]: {
        translations: bgTranslations,
      },
    },
    lng: Languages.EN,
  })
  .then(() => console.log("Loaded translations:"));

export default i18n;
