import { Box, Checkbox, Divider, FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { convertCurrency } from "common/Utils/currency";
import { getFilteredOrders, getVisibleOrders, groupeBySku, netMarginItem } from "common/Utils/orders";
import { FbaFee, Order, ReturnItem } from "common/types";
import PeriodSelection from "components/shared/PeriodSelection";
import { TranslationPrefixes } from "consts/translationPrefixes";
import { CurrencyContext } from "context/currency";
import { endOfDay, startOfDay } from "date-fns";
import useToggleSelect from "hooks/useToggleSelect";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFees, getOrders, getReturnReportData } from "services/service";
import { getColumns, getDynamicColumnsForExport, } from "../../common/Utils/ActionUtils";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import CardComponent from "../shared/CardComponent";
import { CardHeaderActions } from "../shared/CardHeaderActions";
import TableComponent, { Column } from "../shared/TableComponent";
const getCsvData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (typeof el?.key === "string") {
                el[key] = el[key].split("<br/>").join('\n');
            }
        });
        return el;
    })
}

export enum ProductProfitsTableDataKeys {
    sku = "sku",
    asin = "asin",
    quantity = "quantity",
    totalRevenue = "totalRevenue",
    amazonTaxes = "amazonTaxes",
    expensesSoldItems = "expensesSoldItems",
    expensesAds = "expensesAds",
    refunds = "refunds",
    netMargin = "netMargin",
    netProfit = "netProfit",
}

interface ColumnOrders extends Column {
    accessor?: keyof typeof ProductProfitsTableDataKeys;
}

interface TableCalculatedData {
    [ProductProfitsTableDataKeys.netProfit]: number;
    [ProductProfitsTableDataKeys.expensesAds]: number;
    [ProductProfitsTableDataKeys.expensesSoldItems]: number;
    [ProductProfitsTableDataKeys.amazonTaxes]: number;
    [ProductProfitsTableDataKeys.totalRevenue]: number;
    [ProductProfitsTableDataKeys.quantity]: number;
}

function WorkingDashboard() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = useState<string>("");
    const [orders, setOrders] = useState<Order[]>([]);
    const [fees, setFees] = useState<FbaFee[]>([]);
    const [returnedItems, setReturnedItems] = useState<ReturnItem[]>([]);

    const currencyContext = useContext(CurrencyContext);

    useEffect(() => {
        setIsLoading(true);
        Promise.all([getOrders(), getFees(), getReturnReportData()]).then((val) => {
            setOrders(val[0]);
            setFees(val[1]);
            setReturnedItems(val[2]);
            setIsLoading(false);
        }).catch(error => {
            console.error(error);
            setIsLoading(false); // Ensure loading state is reset in case of an error
        });
    }, []);

    const { t } = useTranslation();
    const [startDate, setStartDate] = useState<Date>(startOfDay(new Date())); // Default to start of today
    const [endDate, setEndDate] = useState<Date>(endOfDay(new Date())); // Default to end of today
    const handleDatesChange = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };
    const filteredDateOrders = getFilteredOrders(orders, startDate, endDate)
    const calculatedOrders = getVisibleOrders(filteredDateOrders, fees);

    const separatedOrders = groupeBySku(calculatedOrders);

    const currencyConvert = convertCurrency(currencyContext.currency);

    const tableData = separatedOrders.map(sameSKUorders => {
        // Reduce to calculate total quantity and total revenue
        const combineAllCalculations = sameSKUorders.reduce((acc, order) => {
            acc[ProductProfitsTableDataKeys.quantity] += order.quantity;
            acc[ProductProfitsTableDataKeys.totalRevenue] += (order.revenue);
            acc[ProductProfitsTableDataKeys.amazonTaxes] += (order.fbaFee);
            acc[ProductProfitsTableDataKeys.expensesAds] += (order.adsCost);
            acc[ProductProfitsTableDataKeys.expensesSoldItems] += (order.soldItemsCost);
            acc[ProductProfitsTableDataKeys.netProfit] += (order.netProfit);
            return acc;
        }, {
            quantity: 0,
            totalRevenue: 0,
            amazonTaxes: 0,
            expensesAds: 0,
            expensesSoldItems: 0,
            netProfit: 0,
            refunds: 0
        } as TableCalculatedData);

        for (const key in combineAllCalculations) {
            if (typeof combineAllCalculations[key as keyof TableCalculatedData] === 'number') {
                combineAllCalculations[key as keyof TableCalculatedData] = parseFloat((combineAllCalculations[key as keyof TableCalculatedData]).toFixed(2));
            }
        }

        const netMargin = +netMarginItem({ adsCost: combineAllCalculations[ProductProfitsTableDataKeys.expensesAds], fbaFee: combineAllCalculations[ProductProfitsTableDataKeys.amazonTaxes], totalRevenue: combineAllCalculations[ProductProfitsTableDataKeys.totalRevenue] });
        return {
            sku: sameSKUorders[0].sku,
            asin: sameSKUorders[0].asin,
            netMargin: currencyConvert(netMargin),
            ...combineAllCalculations
        };
    });

    const { allSelectedIds, toggleSelect } = useToggleSelect({ identificatorSelectors: tableData.map(el => el.sku) })
    const { sideBar, actions, workingDashboard } = TranslationPrefixes;

    const createTableCell = (accessor: ProductProfitsTableDataKeys) => {
        return {
            accessor,
            cell: (val: any) => <Typography variant="body1">{val}</Typography>,
            header: t(`${workingDashboard}${accessor}`)
        }
    }

    const tableCells = Object.values(ProductProfitsTableDataKeys)

    const columns: Array<ColumnOrders> = [
        {
            cell: (el) => {
                return (
                    <Checkbox
                        checked={allSelectedIds.includes(el.sku)}
                        onChange={() => toggleSelect({ singleSelector: el.sku })}
                    />
                )
            },
            header: "checkBox"
        },
        ...tableCells.map(createTableCell)
    ];

    let componentRef = useRef<TableComponentToPrint | null>(null);
    const csvColumns = getDynamicColumnsForExport({ t, prefix: TranslationPrefixes.workingDashboard, values: ProductProfitsTableDataKeys });

    const renderHeaderActions = () => {
        if (!allSelectedIds.length) return;
        const selectedData = tableData.filter((el) => allSelectedIds.includes(el.sku));
        const csvData = getCsvData(selectedData);
        const csvFileName = `products_profit_${+new Date()}.csv`;

        return (
            <CardHeaderActions
                csvHeaders={getColumns(csvColumns)}
                csvFileName={csvFileName}
                csvData={csvData}
                componentRef={componentRef}
            />
        );
    }

    return (
        <CardComponent title={t(`${sideBar}productsProfit`)} isLoading={isLoading} headerActions={renderHeaderActions()}>
            <Box>
                <PeriodSelection onDatesChange={handleDatesChange} >
                    <Grid item xs={12} md={3}>
                        <FormControl style={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                onChange={(e) => {
                                    setCurrentSearch(e.target.value as string);
                                }}
                                label={t(`${actions}search`)}
                            />
                        </FormControl>
                    </Grid>
                </PeriodSelection>
                <Box marginBottom="15px" marginTop="15px">
                    <Divider />
                </Box>
                {
                    tableData.length > 0 && (
                        <div style={{ display: "none" }}>
                            <TableComponentToPrint
                                ref={componentRef}
                                data={tableData}
                                headers={csvColumns}
                                title={t(`${sideBar}productsProfit`)}
                            />
                        </div>
                    )
                }
                <TableComponent
                    data={tableData}
                    currentSearch={currentSearch}
                    columns={columns}
                    checkBox={{
                        checked: tableData.length === allSelectedIds.length,
                        onChangeCheckBox: (toggleAll) => {
                            toggleSelect({ toggle: toggleAll })
                        }
                    }}
                />
            </Box>
        </CardComponent>
    )
}

export default WorkingDashboard;
